import Tippy from "@tippyjs/react";
import { ReactComponent as CheckCircleFill } from "bootstrap-icons/icons/check-circle-fill.svg";
import { ReactComponent as ExclamationCircleFill } from "bootstrap-icons/icons/exclamation-circle-fill.svg";
import { Link } from "react-router-dom";
import { STPTaskItem } from "types/api";

interface TaskItemProps {
    task?: STPTaskItem;
}

const TASK_PAGE: Record<string, string> = {
    "Enter STP Details": "/settings/stp-details",
}

const TaskItem = ({ task }: TaskItemProps) => {
    const taskName = task?.taskName;



    return (
        <Link to={taskName ? TASK_PAGE[taskName] : ""}>
            <span className="task-item d-flex align-items-center">
                <CheckCircleFill
                    className={`task-item-check flex-shrink-0 
                ${task?.taskStatus === "COMPLETED" ? "check" : "uncheck"}`}
                />
                <span className="task-item-title">{task?.taskName}</span>
                {task?.taskRequired &&
                    <Tippy content="This task is mandatory in order to access all Wrkr Pay features">
                        <ExclamationCircleFill data-testid="task-item-exclamation" className="task-item-exclamation flex-shrink-0" />
                    </Tippy>
                }
            </span>
        </Link>
    );
}

export default TaskItem;