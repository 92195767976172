/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * STP API
 * STP API Description
 * OpenAPI spec version: 1.0
 */

export type STPDetailsStpVersion =
  (typeof STPDetailsStpVersion)[keyof typeof STPDetailsStpVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const STPDetailsStpVersion = {
  STP1: "STP1",
  STP2: "STP2",
} as const;
