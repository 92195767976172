import Modal from "react-modal";
import CancelButton from "../Buttons/CancelButton";
import ProceedButton from "../Buttons/ProceedButton";

if (process.env.NODE_ENV !== 'test') 
Modal.setAppElement("#root");

const ConfirmationModal = ({
  header,
  message,
  proceedHandler,
  cancelHandler,
}) => {
  return (
    <Modal
      className="modal modal--center"
      overlayClassName="modal-overlay"
      isOpen={true}
      contentLabel="Loading"
    >
      <h3 className="mt-5">{header ? header : "Confirm"}</h3>
      <p className="my-4">
        {message ? message : "Are you sure you want to proceed?"}
      </p>
      <ProceedButton onClick={proceedHandler} />
      <CancelButton onClick={cancelHandler} />
    </Modal>
  );
};

export default ConfirmationModal;
