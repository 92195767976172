import {STPTaskItem, STPTaskItemTaskStatus} from "types/api";
import TaskItem from "./TaskItem";

interface TasksProps {
    tasks?: STPTaskItem[];
    dismiss: () => void;
}

const allTasksCompleted = (tasks?: STPTaskItem[]) => {
    return tasks?.every(task => task.taskStatus === STPTaskItemTaskStatus.COMPLETED);
}
const Tasks = ({tasks, dismiss}: TasksProps) => {
    return (
        <div className="tasks">
            <div className="tasks-title">
                {allTasksCompleted(tasks) && <div className="dismiss-icon" onClick={() => dismiss()}/>}
                <p className="tasks-header">Welcome to Wrkr Pay!</p>
                <p className="tasks-desc">Quickstart guide</p>
            </div>
            <div className="tasks-content">
                {tasks?.map((task, index) => (
                    <TaskItem
                        key={index}
                        task={task}
                    />
                ))}
            </div>
        </div>
    );
}

export default Tasks;