import { useContext, useState } from "react";
import LinkButton from "components/Buttons/LinkButton";
import Tasks from "./Tasks";
import { ParticipantContext } from "App";
import { STPTaskItem, STPTaskItemTaskStatus } from "types/api";
import {updateQuickStartPage} from "types/api/services/stp/stp";

const SetupTasks = () => {
    const [display, setDisplay] = useState(true);
    const { participant, setParticipant } = useContext(ParticipantContext);
    const tasks = participant?.stpTasks;

    const allTasksCompleted = (tasks?: STPTaskItem[]) => {
        return tasks?.every(task => task.taskStatus === STPTaskItemTaskStatus.COMPLETED);
    }

    const dismiss = async () => {
        if (!tasks || allTasksCompleted(tasks)) {
            try {
                const res = await updateQuickStartPage();
                const { data } = res;
                const participantValues = data ;
                setParticipant(participantValues);
                if(res.data.showQuickstart === false) {
                    setDisplay(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    if (!display) return null;

    return (
        <aside data-testid="setup-tasks" className="setup-tasks">
            <Tasks tasks={tasks} dismiss={dismiss}/>
            {allTasksCompleted(tasks) &&
                <LinkButton
                    disabled={!allTasksCompleted(tasks)}
                    text="Dismiss quickstart"
                    onClick={() => dismiss()}
                ></LinkButton>
            }
        </aside>
    );
}

export default SetupTasks;