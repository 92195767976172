import { useContext, useState } from "react";
import CancelButton from "../../components/Buttons/CancelButton";
import { Formik, Form, FormikHelpers } from "formik";
import { STPSchema } from "../../utils/schemas";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import FormGroup from "../../components/FormGroup/FormGroup";
import history from "../../utils/history";
import { toast } from "react-toastify";
import { STPDetails, STPDetailsStpVersion, STPDetailsVerifyStatus } from "types/api";
import { verifyOrUpdateStpDetails } from "types/api/services/stp/stp";
import { ParticipantContext } from "App";

interface BMSIdFormProps {
  stpDetails: STPDetails;
}

interface BMSIdFormValues {
  productId?: string;
  bmsName?: string;
  bmsVendor?: string;
  bmsVersion?: string;
  verifyStatus?: STPDetailsVerifyStatus;
  stpVersion?: STPDetailsStpVersion;
}

const BMSIdForm = ({ stpDetails }: BMSIdFormProps) => {
  const { getParticipant } = useContext(ParticipantContext);

  const [isOpen, setIsOpen] = useState(false);

  const initialValues: BMSIdFormValues = {
    productId: stpDetails?.productId,
    bmsName: stpDetails?.bmsName,
    bmsVendor: stpDetails?.bmsVendor,
    bmsVersion: stpDetails?.bmsVersion,
    verifyStatus: stpDetails?.verifyStatus,
    stpVersion: stpDetails?.stpVersion,
  }

  const handleSubmit = async (values: BMSIdFormValues, { setSubmitting }: FormikHelpers<BMSIdFormValues>) => {
    setSubmitting(true);
    try {
      await verifyOrUpdateStpDetails({ ...values });
      getParticipant();
      setSubmitting(false);
      toast.success(
        () =>
          `${stpDetails?.stpVersion} details ${stpDetails?.verifyStatus === "VERIFIED" ? "saved" : "verified"
          }`,
        { autoClose: 3000 }
      );
      history.goBack();
    } catch (error) {
      setSubmitting(false);
      console.log(error);
    }
  };

  return (
    <div>
      {stpDetails?.verifyStatus === "PENDING_VERIFICATION" && (
        <div className="alert" style={{ maxWidth: "36rem" }}>
          <div className="alert__container">
            <div className="alert__content">
              <p className="alert__title">
                Verify your {stpDetails?.stpVersion} details
              </p>
              <div className="alert__message">
                {" "}
                <p>
                  Please review your {stpDetails?.stpVersion} details and click
                  "<strong>Verify</strong>" to save these details for all future
                  STP report lodgements.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-container">
        <h2>{stpDetails?.stpVersion}</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={STPSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, isSubmitting }) => (
            <Form id="stp-details-form" className="form">
              <FormGroup name="productId" label="STP product ID" />
              <FormGroup name="bmsName" label="BMS product name" />
              <FormGroup name="bmsVendor" label="BMS product vendor" />
              <FormGroup name="bmsVersion" label="BMS product version" />

              {isOpen && (
                <ConfirmationModal
                  header="Discard changes"
                  message="Are you sure you want to discard the changes you made?"
                  proceedHandler={() => {
                    setIsOpen(false);
                    history.goBack();
                  }}
                  cancelHandler={() => {
                    setIsOpen(false);
                  }}
                />
              )}
              <div className="mt-8">
                <span className="mr-6">
                  <button
                    type="submit"
                    className="button button--primary"
                    disabled={isSubmitting}
                  >
                    {stpDetails?.verifyStatus === "VERIFIED"
                      ? "Save"
                      : "Verify"}
                  </button>
                </span>
                {stpDetails?.verifyStatus === "VERIFIED" && (
                  <CancelButton
                    onClick={() => {
                      if (Object.keys(touched).length > 0) {
                        setIsOpen(true);
                      } else {
                        history.goBack();
                      }
                    }}
                  ></CancelButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BMSIdForm;
