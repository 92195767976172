import { FEATURES } from "../utils/configs";

export enum Feature {
    STP_REGISTRATION = "StpRegistration"
}

export interface Features {
    name: Feature;
    active: boolean | string | null;
}

export const isFeatureEnabled = (feature: Feature): boolean => {
    return !!features?.find(f => f.name === feature && (f.active?.toString() === 'true')) || false;
}

// Features will be configured here and turned on/off per-environment as needed via deployment config.
const features: Features[] = [{
    name: Feature.STP_REGISTRATION,
    active: FEATURES.STP_REGISTRATION_ACTIVE
}]

