import { Feature, isFeatureEnabled } from "./features";

interface FeatureToggleProps {
  feature: Feature;
  children: React.ReactNode;
}

export const FeatureToggle = ({ feature, children }: FeatureToggleProps) => {
  return (
    <>
      {isFeatureEnabled(feature) ? <>{children}</> : <></>}
    </>
  );
};