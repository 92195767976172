import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { ReactComponent as Plus } from "bootstrap-icons/icons/plus.svg";

const Employees = () => {
  return (
    <div className="Employees">
      <Breadcrumbs />
      <div className="d-flex align-items-center justify-content-between mb-8">
        <h1 className="mb-0">Employees</h1>
        <Link
          to="/employees/new"
          className="button button--outline button--icon-before"
        >
          <Plus className="button--icon-before__icon" /> New employee
        </Link>
      </div>

      <section className="error-section">
        <h2>No employees</h2>
        <p>You currently have no employees</p>
        <Link
          to="/employees/new"
          className="button button--primary button--icon-before"
        >
          <Plus className="button--icon-before__icon" /> New employee
        </Link>
      </section>

      {/* <PayeeData processId="Abacus20210408004534" readOnly={false} /> */}
    </div>
  );
};

export default Employees;
