import axios from "axios";
import { getToken, isValidToken } from "./token";
import { API_URL_STP_SERVICE } from "./configs";
import history from "./history.js";

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
  async (config) => {
    try {
      let token = getToken();
      if (isValidToken()) {
        config.headers.Authorization = token;
        config.baseURL = `${API_URL_STP_SERVICE}/`;
        return config;
      } else {
        history.push("/forbidden");
      }
    } catch {
      throw new axios.Cancel("Operation canceled by the user.");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default HttpClient;
