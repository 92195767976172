import { ReactComponent as Logo } from "../../assets/images/logo_wrkr-pay.svg";
import {backToClickSuper} from "../../utils/siteNavigation";



const Forbidden = () => {
  return (
    <div className="page__background">
      <div className="mb-8">
        <Logo />
      </div>
      <h1 className="title">403</h1>
      <h1>We are sorry...</h1>
      <p className="longform mb-2">
        The page you're trying to access has been denied, or your session has
        expired.
      </p>
      <p className="longform mb-8">
        Please return to ClickSuper and try again.
      </p>
      <button className="button button--primary" onClick={() => backToClickSuper()}>Return to ClickSuper</button>
    </div>
  );
};

export default Forbidden;
