interface LinkButtonProps {
    text: string;
    disabled: boolean;
    onClick: () => void;
}

const LinkButton = ({ text, disabled, onClick }: LinkButtonProps) => {
    return (
        <button
            type="button"
            className="button--link"
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default LinkButton;