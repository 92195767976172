/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * STP API
 * STP API Description
 * OpenAPI spec version: 1.0
 */

export type STPTaskItemTaskStatus =
  (typeof STPTaskItemTaskStatus)[keyof typeof STPTaskItemTaskStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const STPTaskItemTaskStatus = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  INCOMPLETED: "INCOMPLETED",
} as const;
